import { EventEmitter } from '@angular/core';
/** Takes a list of items and handles selection for them. */
export class Selection {
  items;
  pkField;
  /** Triggers whenever any selection change happen. */
  onChange = new EventEmitter();
  /** Triggers when an item is selected. */
  onSelect = new EventEmitter();
  /**
   * Triggers when all items are selected.
   * Only when {@link Selection.selectAll} is called.
   */
  onSelectAll = new EventEmitter();
  /** Triggers when an item is deselected. */
  onDeselect = new EventEmitter();
  /**
   * Triggers when all items are deselected.
   * Only when {@link Selection.deselectAll} is called.
   */
  onDeselectAll = new EventEmitter();
  /**
   * Selection dict.
   *
   * This is where we keep which item is selected (value true)
   * and which item is not (value not true).
   */
  selection = {};
  /** Number of selected items. */
  selected = 0;
  /** Number of deselected items. */
  deselected = 0;
  /** Is selection indeterminate? */
  indeterminate = false;
  /**
   * @returns List of original items that are selected
   */
  get selectedItems() {
    return this.items.filter(item => this.selection[this.getItemPK(item)]);
  }
  /** @returns Same as {@link selectedItems} but returns IDs only. */
  get selectedItemsIds() {
    return this.selectedItems.map(item => this.getItemPK(item));
  }
  /**
   * @param items List of original items to set up selection for.
   * @param pkField Item unique property key to store selection by.
   * @param pksSelected List of item PKs that are selected.
   */
  constructor(items, pkField = 'id', pksSelected = []) {
    this.items = items;
    this.pkField = pkField;
    if (pksSelected) {
      this.selectPks(pksSelected);
    } else {
      this.count();
    }
  }
  /** @returns the PK of the given item. */
  getItemPK(item) {
    return item[this.pkField];
  }
  /** Reset all selection and update the count. */
  reset() {
    this.selection = {};
    this.count();
    this.deselectAll();
    this.onChange.emit();
  }
  /**
   * Update selected and deselected numbers.
   * Also update indeterminate status.
   */
  count() {
    this.selected = Object.values(this.selection).filter(status => status).length;
    this.deselected = this.items.length - this.selected;
    this.indeterminate = this.selected > 0 && this.deselected > 0;
  }
  /**
   * Select an item.
   *
   * @param item to select.
   * @param count Whether to generate counts (default is true).
   */
  select(item, count = true) {
    this.selection[this.getItemPK(item)] = true;
    if (count) {
      this.count();
    }
    this.onChange.emit();
    this.onSelect.emit(item);
  }
  /**
   * Deselect an item.
   *
   * @param item to deselect.
   * @param count Whether to generate counts (default is true).
   */
  deselect(item, count = true) {
    this.selection[this.getItemPK(item)] = false;
    this.onChange.emit();
    this.onDeselect.emit(item);
    if (count) {
      this.count();
    }
  }
  /**
   * Toggle an item selection status.
   *
   * @param item to toggle selection.
   * @param count Whether to generate counts (default is true).
   */
  toggle(item, count = true) {
    if (this.selection[this.getItemPK(item)]) {
      this.deselect(item, count);
    } else {
      this.select(item, count);
    }
  }
  /**
   * Select the given items PKs
   *
   * @param pks to select.
   */
  selectPks(pks) {
    const items = this.items.filter(item => pks.includes(this.getItemPK(item)));
    for (const item of items) {
      this.select(item, false);
    }
    this.count();
  }
  /** Select all items. */
  selectAll() {
    for (const item of this.items) {
      this.select(item, false);
    }
    this.count();
    this.onSelectAll.emit();
  }
  /** Deselect all items. */
  deselectAll() {
    for (const item of this.items) {
      this.deselect(item, false);
    }
    this.count();
    this.onDeselectAll.emit();
  }
  /**
   * Select all if selection is indeterminate or
   * no item is selected otherwise deselect all.
   */
  selectDeselectAll() {
    if (this.indeterminate || !this.selected) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }
}