/**
 * Represents a timer that can be started, stopped,
 * and repeated at a specified rate.
 */
export class Timer {
  /** Is this a repeating timer? */
  repeat;
  /** Timer rate in milliseconds. */
  rate;
  /** Invoked on timer invoke. */
  onInvoke;
  /** Invoked when timer starts. */
  onStart;
  /** Invoked when timer stops. */
  onStop;
  /** Reference of the active timer. */
  reference;
  /**
   * Gets the method used to create the timer (interval or timeout).
   * @returns The timer creation method.
   */
  get createMethod() {
    if (this.repeat) {
      return setInterval;
    }
    return setTimeout;
  }
  /**
   * Determines whether the timer has started.
   * @returns A boolean indicating if the timer has started.
   */
  get started() {
    return Boolean(this.reference);
  }
  /**
   * Determines whether the timer has stopped.
   * @returns A boolean indicating if the timer has stopped.
   */
  get stopped() {
    return !this.started;
  }
  /**
   * Creates a new Timer instance.
   * @param data The configuration data for the timer.
   */
  constructor(data) {
    /** Store initial data. */
    this.repeat = data.repeat;
    this.rate = data.rate;
    this.onInvoke = data.onInvoke;
    this.onStart = data.onStart;
    this.onStop = data.onStop;
    /** Start the timer if auto-start is enabled. */
    if (data.autoStart) {
      this.start();
    }
  }
  /**
   * Starts the timer.
   *
   * If the timer is already started, it will be stopped
   * first and then restarted.
   */
  start() {
    if (this.started) {
      this.stop();
    }
    this.reference = this.createMethod(this.onInvoke, this.rate);
    this.onStart?.();
  }
  /**
   * Stops the timer if it is currently running.
   * If the timer is a repeating timer, it will be stopped
   * using clearInterval.
   * If the timer is a non-repeating timer, it will be
   * stopped using clearTimeout.
   */
  stop() {
    if (this.started) {
      if (this.repeat) {
        clearInterval(this.reference);
      } else {
        clearTimeout(this.reference);
      }
      this.reference = undefined;
      this.onStop?.();
    }
  }
  /**
   * Triggers the callback function associated with the timer.
   * This method can be called manually to simulate the timer invocation.
   */
  invoke() {
    this.onInvoke();
  }
}