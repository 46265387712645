import { isArray } from './is-array';
/**
 * Check whether the given value is a dict.
 * @param value Value to check.
 * @returns true if given value is a dict.
 */
export function isDict(value) {
  if (!value) {
    return false;
  }
  if (isArray(value)) {
    return false;
  }
  return value.constructor === Object;
}