/**
 * Attempts to find an item in the list of dictionaries
 * with the given property name and value.
 *
 * @param list List to search.
 * @param itemValue Item property value to match.
 * @param itemKey Item property name to match (default is "id").
 *
 * @returns Item that is found (undefined otherwise).
 */
export function findItemInList(list, itemValue, itemKey = 'id') {
  return list.find(item => item[itemKey] === itemValue);
}