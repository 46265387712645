import { slugify } from '../functions';
import { InlineStorage } from './inline-storage';
/** Link group used for group of links in the UI (tabs, links, etc.). */
export class LinkGroup {
  /** ID of this link group. */
  id;
  /** UI collapse status of this link group in form of {@see InlineStorage}. */
  collapse;
  /** UI label of this link group. */
  label;
  /** UI links of this link group. */
  links;
  /** Is enabled? (Should show this link group on UI?). */
  enable = true;
  /** Method that its return value sets value of {@see enable}. */
  access;
  constructor(data) {
    this.id = slugify(data.label);
    this.label = data.label;
    this.links = data.links;
    this.collapse = new InlineStorage(`link-group-${this.id}`, false);
    if ('access' in data) {
      this.access = data.access;
    }
  }
  /** Set value of {@see enable} using {@see access}. */
  generateEnable(updateLinks = false, disableIfNoActiveLinks = true) {
    if (this.access) {
      this.enable = this.access();
    }
    if (updateLinks) {
      for (const link of this.links) {
        link.generateEnable();
      }
    }
    if (disableIfNoActiveLinks) {
      this.enable = this.links.some(link => link.enable);
    }
  }
  /** Toggle collapse status of this link group. */
  toggleCollapse() {
    this.collapse.value = !this.collapse.value;
  }
}