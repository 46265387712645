import { isArray } from './is-array';
import { isArrayEqual } from './is-array-equal';
import { isDict } from './is-dict';
/**
 * Checks the given dicts for equality.
 *
 * @param a First dict to compare.
 * @param b Second dict to compare.
 *
 * @returns Whether given dicts are equal.
 */
export function isDictEqual(a, b) {
  const keysA = Object.keys(a);
  const keysB = Object.keys(b);
  if (keysA.length !== keysB.length) {
    return false;
  }
  for (const key of keysA) {
    const valueA = a[key];
    const valueB = b[key];
    if (isDict(valueA) && isDict(valueB)) {
      if (!isDictEqual(valueA, valueB)) {
        return false;
      }
    } else if (isArray(valueA) && isArray(valueB)) {
      if (!isArrayEqual(valueA, valueB)) {
        return false;
      }
    } else if (valueA !== valueB) {
      return false;
    }
  }
  return true;
}