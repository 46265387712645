/**
 * Gives complete control over page title and making
 * it easy to make the app have title for each section,
 * page, and branding with a separator.
 *
 * Title format goes like this:
 * "PAGE_NAME SEPARATOR SECTION SEPARATOR BRAND".
 */
export class TitleBaseService {
  title;
  brand;
  separator;
  /** Section goes between the page name and brand. */
  _section = null;
  /** Page name goes at the beginning of the title. */
  _pageName = null;
  /** Change title using the page name and consider the section too. */
  set pageName(pageName) {
    this._pageName = pageName;
    this.generateTitle();
  }
  /** Change the section and update title. */
  set section(section) {
    this._section = section;
    this.generateTitle();
  }
  /**
   * @param title Required to setting title of app (Angular injection).
   * @param brand Brand goes at the end of the title.
   * @param separator Separator is between page name, brand, and section.
   */
  constructor(title, brand = 'Savand Bros Chat', separator = '-') {
    this.title = title;
    this.brand = brand;
    this.separator = separator;
  }
  /** Use page name, section, and brand to generate a title. */
  generateTitle() {
    const title = [this.brand];
    if (this._section) {
      title.unshift(this._section);
    }
    if (this._pageName) {
      title.unshift(this._pageName);
    }
    this.title.setTitle(title.join(` ${this.separator} `));
  }
  /** Reset everything and change title to the brand only. */
  reset() {
    this._pageName = null;
    this._section = null;
    this.generateTitle();
  }
  /** Remove page name from the title. */
  clearPageName() {
    this.pageName = null;
  }
  /** Remove section from the title. */
  clearSection() {
    this.section = null;
  }
}