import { EventEmitter } from '@angular/core';
import { InlineStorage } from '../classes';
/**
 * List of available themes.
 *
 * - `auto`: Automatically selects the theme based on
 * the user's system preferences.
 * - `light`: Light theme.
 * - `dark`: Dark theme.
 */
export const THEMES = ['auto', 'light', 'dark'];
/**
 * Service for managing the application's theme.
 *
 * This service is responsible for storing the selected theme in local storage
 * and applying it to the application. It also supports live theme changes
 * based on user preference or system settings.
 *
 * This service is best used in conjunction with a Bootstrap-based application
 * that supports both light and dark themes.
 *
 * **Usage:**
 * - Instantiate the service in your Angular application.
 * - Call the {@link initiate} method during the
 * application's initialization process.
 */
export class ThemeBaseService {
  document;
  mediaMatcher;
  /** Media query used to detect if the user's system prefers a dark theme. */
  darkQuery = '(prefers-color-scheme: dark)';
  /**
   * InlineStorage instance that manages the current theme in local storage.
   *
   * The theme is stored with a key of `'theme'`, and
   * the default theme is the first element in {@link THEMES}.
   */
  storage = new InlineStorage('theme', THEMES[0]);
  /**
   * EventEmitter that triggers whenever the theme changes.
   *
   * Subscribers can use this to react to theme changes in real-time.
   */
  themeChange = new EventEmitter();
  /** @returns The `<html>` HTML element of the document. */
  get html() {
    return this.document.documentElement;
  }
  /**
   * @returns The current theme, validated against the available themes.
   *
   * If the stored theme is not in {@link THEMES}, it
   * falls back to the default theme.
   */
  get theme() {
    const stored = this.storage.value;
    if (stored) {
      if (THEMES.includes(stored)) {
        return stored;
      }
      return this.storage.defaultValue;
    }
    return this.storage.defaultValue;
  }
  /**
   * Changes the current theme.
   *
   * @param value The new theme to apply. Must be one of the {@link THEMES}.
   */
  set theme(value) {
    this.setTheme(value);
  }
  /**
   * @returns The value of the `[data-bs-theme]` attribute
   * from the `<html>` element.
   */
  get dataBsTheme() {
    return this.html.getAttribute('data-bs-theme');
  }
  /**
   * Sets the theme in the `[data-bs-theme]` attribute of the `<html>` element.
   *
   * @param value The new theme to set. Must be one of the {@link THEMES}.
   */
  set dataBsTheme(value) {
    this.html.setAttribute('data-bs-theme', value);
  }
  /** @returns `true` if the current theme is dark; otherwise, `false`. */
  get isDark() {
    return this.dataBsTheme === 'dark';
  }
  /** @returns `true` if the current theme is light; otherwise, `false`. */
  get isLight() {
    return this.dataBsTheme === 'light';
  }
  /**
   * @returns `true` if the current theme is set
   * to `auto`; otherwise, `false`.
   */
  get isAuto() {
    return this.theme === 'auto';
  }
  /**
   * Constructs the ThemeBaseService.
   *
   * @param document The global document object.
   * @param mediaMatcher The MediaMatcher service used to match media queries.
   */
  constructor(document, mediaMatcher) {
    this.document = document;
    this.mediaMatcher = mediaMatcher;
  }
  /**
   * Sets the theme and updates the `[data-bs-theme]`
   * attribute of the `<html>` element.
   *
   * This method ensures that the theme is stored in local storage and applies
   * the appropriate theme based on the current settings and system preferences.
   *
   * @param value The theme to set. Must be one of the {@link THEMES}.
   */
  setTheme(value) {
    this.storage.value = value;
    let theme = 'light';
    if (this.theme === 'dark' || this.theme === 'auto' && this.mediaMatcher.matchMedia(this.darkQuery).matches) {
      theme = 'dark';
    }
    this.dataBsTheme = theme;
    this.themeChange.emit(theme);
  }
  /**
   * Initializes the theme service.
   *
   * This method must be called during the application's initialization process.
   * It sets the initial theme and attaches an event listener to handle changes
   * in the system's color scheme preference.
   */
  initiate() {
    this.setTheme(this.theme);
    this.mediaMatcher.matchMedia(this.darkQuery).addEventListener('change', () => {
      if (this.isAuto) {
        this.setTheme(this.theme);
      }
    });
  }
}