import { BehaviorSubject, from, tap } from 'rxjs';
/** Handling browser desktop notification API. */
export class NotificationBaseService {
  window;
  /**
   * Flag for whether notifications are supported
   * on this device.
   */
  supported;
  /**
   * Whether to show user that they have not allowed
   * notifications yet.
   *
   * Only true when user has not denied or granted
   * notification permission.
   */
  showNotificationRequest;
  /** @returns Notification permission on this device.  */
  get permission() {
    if (!this.supported) {
      return 'denied';
    }
    return Notification.permission;
  }
  /**
   * @returns Whether notification permission is granted
   * on this device.
   */
  get isGranted() {
    return this.permission === 'granted';
  }
  constructor(window) {
    this.window = window;
    this.supported = 'Notification' in this.window;
    this.showNotificationRequest = new BehaviorSubject(this.permission === 'default');
  }
  /**
   * Requests permission from the user to display
   * desktop notifications.
   *
   * @returns An observable that resolves to the notification
   * permission status.
   */
  request() {
    return from(Notification.requestPermission()).pipe(tap(permission => {
      this.showNotificationRequest.next(permission === 'default');
    }));
  }
  /**
   * Displays a desktop notification with the provided
   * options.
   *
   * @param title The title of the notification.
   *
   * @param options Configuration options for the
   * notification (optional).
   *
   * @param onClick Callback function to execute when
   * the notification is clicked (optional).
   */
  show(title, options, onClick) {
    if (this.isGranted) {
      const notification = new Notification(title, options);
      notification.onclick = () => {
        this.window.focus();
        onClick?.();
      };
      return notification;
    }
    return null;
  }
}