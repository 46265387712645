import { isArray } from './is-array';
import { isArrayEqual } from './is-array-equal';
import { isDict } from './is-dict';
/**
 * Compares two dictionaries and returns the key-value pairs that have changed.
 *
 * @param source The source dictionary to compare.
 * @param target The target dictionary to compare.
 *
 * @returns A new dictionary containing the key-value pairs that have changed.
 */
export function getChangedFromDicts(source, target) {
  const output = {};
  // Check each key in source
  for (const key in source) {
    if (key in source && key in target) {
      const sourceValue = source[key];
      const targetValue = target[key];
      // If the value is a dictionary, recursively compare nested dictionaries
      if (isDict(sourceValue) && isDict(targetValue)) {
        const nestedChanges = getChangedFromDicts(sourceValue, targetValue);
        if (Object.keys(nestedChanges).length > 0) {
          output[key] = nestedChanges;
        }
      }
      // If the value is an array, compare the arrays
      else if (isArray(sourceValue) && isArray(targetValue)) {
        if (!isArrayEqual(sourceValue, targetValue)) {
          output[key] = targetValue;
        }
      }
      // If the value is a Date object, compare the dates
      else if (sourceValue instanceof Date && targetValue instanceof Date) {
        if (sourceValue.getTime() !== targetValue.getTime()) {
          output[key] = targetValue;
        }
      }
      // If the value is neither a dictionary, array, nor Date,
      // and the values are different, add to output
      else if (sourceValue !== targetValue) {
        output[key] = targetValue;
      }
    }
  }
  // Check each key in target that doesn't exist in source
  for (const key in target) {
    if (key in target && !(key in source)) {
      output[key] = target[key];
    }
  }
  return output;
}