import { isDict } from './is-dict';
import { isDictEqual } from './is-dict-equal';
/**
 * Checks the given arrays for equality.
 *
 * @param a First array to compare.
 * @param b Second array to compare.
 *
 * @returns Whether given arrays are equal.
 */
export function isArrayEqual(a, b) {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (isDict(a[i]) && isDict(b[i])) {
      if (!isDictEqual(a[i], b[i])) {
        return false;
      }
    } else if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}