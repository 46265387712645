import { EventEmitter } from '@angular/core';
/**
 * When you want a variable to stay in local storage
 * and load it with a single line of code.
 *
 * T is type of data to store.
 */
export class InlineStorage {
  key;
  defaultValue;
  /** Data value that's stored. */
  _value;
  /** Emits when the stored data is changed. */
  change = new EventEmitter();
  /**
   * Last value that was set, can be changed without
   * effecting storage.
   */
  lastValue = this.value;
  /** @returns the current value of stored data. */
  get value() {
    return this._value;
  }
  /**
   * Change the stored data and update the storage.
   *
   * @param value New data value.
   */
  set value(value) {
    this._value = value;
    localStorage[this.key] = JSON.stringify(value);
    this.lastValue = value;
    this.change.emit(value);
  }
  /**
   * @param key Unique key of this data.
   * @param defaultValue Value that is used if it's not stored before.
   */
  constructor(key, defaultValue) {
    this.key = key;
    this.defaultValue = defaultValue;
    if (key in localStorage) {
      try {
        this._value = JSON.parse(localStorage[key]);
      } catch (error) {
        console.error('[InlineStorage] Error parsing data from localStorage', error);
        this._value = defaultValue;
      }
    } else {
      this._value = defaultValue;
      this.value = defaultValue;
    }
  }
  /**
   * Remove this object from storage completely
   * and reset it to its initial value.
   */
  clear() {
    this._value = this.defaultValue;
    delete localStorage[this.key];
    this.change.emit(this._value);
  }
}