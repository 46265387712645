/**
 * Removes the given item from the given list.
 *
 * @param list The list from which the item should be removed.
 * @param child The item to be removed from the list.
 */
export function removeChild(list, child) {
  const index = list.indexOf(child);
  if (index !== -1) {
    list.splice(index, 1);
  }
}