import { BehaviorSubject } from 'rxjs';
/**
 * Used for storing an object PK and data to let child
 * components to subscribe, use, and change them.
 *
 * Main parent component must load the PK from query
 * params and set the PK. Load the data and set the
 * data.
 */
export class DetailBaseService {
  /** Current object PK subject. */
  pk = new BehaviorSubject(null);
  /** Current object data subject. */
  data = new BehaviorSubject(null);
  /** Clear out all subjects. */
  clear() {
    this.pk.next(null);
    this.data.next(null);
  }
}