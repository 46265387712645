/**
 * Generates a slugified string from the given text.
 *
 * @param text The text to be slugified.
 * @param replacement The character used to replace spaces (Default is "-").
 *
 * @returns The slugified string.
 */
export function slugify(text, replacement = '-') {
  if (!text) {
    text = '';
  }
  const normalizedText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return normalizedText.toLowerCase().replace(/[^a-z0-9]+/g, replacement).replace(new RegExp(`${replacement}{2,}`, 'g'), replacement).replace(new RegExp(`^${replacement}|${replacement}$`, 'g'), '');
}