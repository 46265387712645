/** Single link used for UI (can be used via {@see LinkGroup}). */
export class Link {
  /** UI label of this link. */
  label;
  /** Router link of this link. */
  routerLink;
  /** UI information of this link. */
  info;
  /** Is enabled? (Should show this link on UI?). */
  enable = true;
  /** Method that its return value sets value of {@see enable}. */
  access;
  constructor(data) {
    this.label = data.label;
    this.routerLink = data.routerLink;
    if ('info' in data) {
      this.info = data.info;
    }
    if ('access' in data) {
      this.access = data.access;
    }
  }
  /** Set value of {@see enable} using {@see access}. */
  generateEnable() {
    if (this.access) {
      this.enable = this.access();
    }
  }
}